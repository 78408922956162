<!-- 职业报告倾向 mobile -->

<template>
  <div class="main">
    <div class="print-page" id="cover" name="cover">
      <h1 class="lj-text-center">职业倾向报告</h1>
      <p>
        通过问卷问题的反馈，为了给您提供更加准确的参考，我们了解您的兴趣偏向（如下图）。众所周知，兴趣是人们从事某项工作的动力。科学认识自我的兴趣是做出专业选择的第一步。
      </p>
      <h2>兴趣评测结果</h2>
      <table class="result">
        <tbody>
          <tr>
            <td>
              现实型(R):<strong>{{ holland_r }}</strong
              >分
            </td>
            <td>
              研究型(I):<strong>{{ holland_i }}</strong
              >分
            </td>
          </tr>
          <tr>
            <td>
              艺术型(A):<strong>{{ holland_a }}</strong
              >分
            </td>
            <td>
              社会型(S):<strong>{{ holland_s }}</strong
              >分
            </td>
          </tr>
          <tr>
            <td>
              企业型(E):<strong>{{ holland_e }}</strong
              >分
            </td>
            <td>
              常规型(C):<strong>{{ holland_c }}</strong
              >分
            </td>
          </tr>
        </tbody>
      </table>
      <div id="hollandCanvas" style="width:80%; height: 400px; margin-left: auto; margin-right: auto;">
        </div>
      <h2>兴趣测评结果解释</h2>
      <table
        class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover"
      >
        <thead>
          <tr>
            <th class="lj-text-middle">兴趣类型</th>
            <th class="lj-text-middle">您的得分</th>
            <th class="lj-text-middle">共同特征</th>
            <th class="lj-text-middle">典型职业</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="lj-text-middle">现实型（R）</td>
            <td>{{ holland_r }}</td>
            <td>
              愿意使用工具从事操作性工作，动手能力强，做事手脚灵活，动作协调。偏好于具体任务，不善言辞，做事保守，较为谦虚。缺乏社交能力，通常喜欢独立做事。
            </td>
            <td>
              喜欢使用工具、机器，需要基本操作技能的工作。对要求具备机械方面才能、体力或从事与物件、机器、工具、运动器材、植物、动物相关的职业有兴趣，并具备相应能力。如：技术性职业（计算机硬件人员、摄影师、制图员、机械装配工），技能性职业（木匠、厨师、技工、修理工、农民、一般劳动）。
            </td>
          </tr>
          <tr>
            <td class="lj-text-middle">研究型 (I）</td>
            <td>{{ holland_i }}</td>
            <td>
              思想家而非实干家，抽象思维能力强，求知欲强，肯动脑，善思考，不愿动手。喜欢独立的和富有创造性的工作。知识渊博，有学识才能，不善于领导他人。考虑问题理性，做事喜欢精确，喜欢逻辑分析和推理，不断探讨未知的领域。
            </td>
            <td>
              喜欢智力的、抽象的、分析的、独立的定向任务，要求具备智力或分析才能，并将其用于观察、估测、衡量、形成理论、最终解决问题的工作，并具备相应的能力。如科学研究人员、教师、工程师、电脑编程人员、医生、系统分析员。
            </td>
          </tr>
          <tr>
            <td class="lj-text-middle">艺术型 （A）</td>
            <td>{{ holland_a }}</td>
            <td>
              有创造力，乐于创造新颖、与众不同的成果，渴望表现自己的个性，实现自身的价值。做事理想化，追求完美，不重实际。具有一定的艺术才能和个性。善于表达、怀旧、心态较为复杂。
            </td>
            <td>
              喜欢的工作要求具备艺术修养、创造力、表达能力和直觉，并将其用于语言、行为、声音、颜色和形式的审美、思索和感受，具备相应的能力，不善于事务性工作。如艺术方面（演员、导演、艺术设计师、雕刻家、建筑师、摄影家、广告制作人），音乐方面（歌唱家、作曲家、乐队指挥），文学方面（小说家、诗人、剧作家）。
            </td>
          </tr>
          <tr>
            <td class="lj-text-middle">社会型 （S）</td>
            <td>{{ holland_s }}</td>
            <td>
              喜欢与人交往、不断结交新的朋友、善言谈、愿意教导别人。关心社会问题、渴望发挥自己的社会作用。寻求广泛的人际关系，比较看重社会义务和社会道德。
            </td>
            <td>
              喜欢要求与人打交道的工作，能够不断结交新的朋友，从事提供信息、启迪、帮助、培训、开发或治疗等事务，并具备相应能力。如:
              教育工作者（教师、教育行政人员），社会工作者（咨询人员、公关人员）。
            </td>
          </tr>
          <tr>
            <td class="lj-text-middle">企业型 （E）</td>
            <td>{{ holland_e }}</td>
            <td>
              追求权力、权威和物质财富，具有领导才能。喜欢竞争、敢冒风险、有野心、抱负。为人务实，习惯以利益得失，权利、地位、金钱等来衡量做事的价值，做事有较强的目的性。
            </td>
            <td>
              喜欢要求具备经营、管理、劝服、监督和领导才能，以实现机构、政治、社会及经济目标的工作，并具备相应的能力。如项目经理、销售人员，营销管理人员、政府官员、企业领导、法官、律师。
            </td>
          </tr>
          <tr>
            <td class="lj-text-middle">常规型 （C）</td>
            <td>{{ holland_c }}</td>
            <td>
              尊重权威和规章制度，喜欢按计划办事，细心、有条理，习惯接受他人的指挥和领导，自己不谋求领导职务。喜欢关注实际和细节情况，通常较为谨慎和保守，缺乏创造性，不喜欢冒险和竞争，富有自我牺牲精神。
            </td>
            <td>
              喜欢要求注意细节、精确度、有系统有条理，具有记录、归档、据特定要求或程序组织数据和文字信息的职业，并具备相应能力。如：秘书、办公室人员、记事员、会计、行政助理、图书馆管理员。
            </td>
          </tr>
        </tbody>
      </table>
      <p class="page-footer no-indent">
        本产品由灯塔教育专利智能专业匹配算法驱动（专利号：201810642977.1）
      </p>
    </div>
    <div class="print-page">
      <h1>相关知识</h1>
      <p>
        兴趣测验的研究可以追溯到20世纪初，桑代克于1912
        年对兴趣和能力的关系进行了探讨。1915年詹穆士发展了一个关于兴趣的问卷，标志着兴趣测验的系统研究的开始。1927年，斯特朗编制了斯特朗职业兴趣调查表，是最早的职业兴趣测验。库德又在1939
        年发表了库德爱好调查表。1953年编制了职业偏好量表，并在此基础上发展了自我指导探索（1969），据此提出了“人格特质与工作环境相匹配”的理论（1970）。
      </p>
      <p>
        约翰•霍兰德 （John
        Holland）是美国约翰•霍普金斯大学心理学教授，美国著名的职业指导专家。他于1959年提出了具有广泛社会影响的职业兴趣理论。约翰•霍兰德认为人的人格类型、兴趣与职业密切相关，霍兰德认为可将职业兴趣划分为六种类型:现实型(Realistic)、研究型(Investigative)、艺术型(Artistic)、社会型(Social)、企业型(Enterprising)和常规型(Conventional)。
      </p>
      <p>
        霍兰德以职业兴趣理论为基础，先后编制了职业偏好量表(VocatIonaI Preference
        lnventory)和自我导向搜寻表(Self-directed
        Search)两种职业兴趣量表，作为职业兴趣的测查工具，霍兰德力求为每种职业兴趣找出两种相匹配的职业能力。约翰•霍兰德职业兴趣测试量表将帮助人们发现和确定自己的职业兴趣和能力特长，从而更好地做出职业决策。在职业兴趣测试的帮助下，个体可以清晰地了解自己的职业兴趣类型和在职业选择中的主观倾向，从而在纷繁的职业机会中找寻到最适合自己的职业，避免职业选择中的盲目行为。尤其是对于学生和缺乏职业经验的人，约翰•霍兰德的职业兴趣理论可以帮助做好职业选择和职业设计，成功地进行职业调整，从整体上认识和发展自己的职业能力。作为职业选择的首选工具，Holland职业兴趣量表被国内外几乎所有的职业机构应用，通过长时间、大批量的测试，信度和效度都比较可靠，深受广大使用者好评。
      </p>
      <div class="button-wrapper">
        <a
          class="lj-btn lj-btn-primary"
          v-if="!questionaireMbtiDone"
          @click="jumpPage('HomeMajorStdMatchPage')"
          style="margin-right: 1rem;"
          >继续测评</a
        ><a
          class="lj-btn lj-btn-secondary"
          style="margin-right: 1rem;"
          @click="jumpPage('reportCenter')"
          >返回测评导航</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import './../../../../Public/utils/chartjs/Chart'
import './../../../../Public/utils/highcharts/highcharts'
import 'vue-chartjs'
import 'chart.js'
import { queryHolland, queryMajorDone } from './../../../../api/major'

export default {
  data: function () {
    return {
      holland_r: 40,
      holland_i: 60,
      holland_a: 20,
      holland_s: 45,
      holland_e: 78,
      holland_c: 81,
      
      questionaireMbtiDone: true
    }
  },
  computed: {
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    print: function () {
      this.$router.push({
        path: 'HomeMajorStdPrint'
      })
    }
  },
  mounted: function () {
    if (this.isMobile === null) {
      this.$router.push({
        path: 'HomeMajorStdResultPage1'
      })
      return
    }
    let o = this
    queryHolland({}).then(res => {
      if (res.rescode == 200) {
        o.holland_r = parseInt(res.data.holland_r)
        o.holland_i = parseInt(res.data.holland_i)
        o.holland_a = parseInt(res.data.holland_a)
        o.holland_s = parseInt(res.data.holland_s)
        o.holland_e = parseInt(res.data.holland_e)
        o.holland_c = parseInt(res.data.holland_c)

        var leidaGraph = this.$echarts.init(
          document.getElementById('hollandCanvas')
        )
        var option
        option = {
          color: ['#f17c67'],
          radar: [
            {
              color: ['#000000'],
              indicator: [
                { name: '现实(R)', max: 100 },
                { name: '研究(I)', max: 100 },
                { name: '艺术(A)', max: 100 },
                { name: '社会(S)', max: 100 },
                { name: '企业(E)', max: 100 },
                { name: '常规(C)', max: 100 }
              ]
            }
          ],
          series: [
            {
              type: 'radar',
              data: [
                {
                  value: [
                    o.holland_r,
                    o.holland_i,
                    o.holland_a,
                    o.holland_s,
                    o.holland_e,
                    o.holland_c
                  ],
                  name: '测评结果'
                }
              ]
            }
          ]
        }
        option && leidaGraph.setOption(option)

        queryMajorDone({}).then(res => {
          if (res.rescode == 200) {
            this.questionaireMbtiDone = res.cur_q > 53 || res.cur_q === -1
          }
        })
      }
    })
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
</style>
